import { post } from '@/utils/request'

//查看自己申购审核列表
export function PostListPurchase(data) {
    return post('finance/purchase/listPurchase', data)
}
//发起申购
export function PostAddPurchase(data) {
    return post('finance/purchase/addPurchase', data)
}
//申购类型下拉
export function PostSelectType(data) {
    return post('finance/type/selectType', data)
}
//付款方式下拉
export function PostPaySelectPay(data) {
    return post('finance/pay/selectPay', data)
}