<template>
  <div class="user">
    <!-- 面包屑导航 -->
    <all-log :dialogLog="dialogLog" />
    <el-card>
        <div class="menu-box">
            <h2>我的申购审核列表</h2>
            <div class="box-bd">
                <!-- 头 -->
      <el-row :gutter="20">
        <el-col :span="5">
          <el-input placeholder="请输入内容" v-model="getInfo.info">
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </el-col>
        <el-col :span="2">
        <el-tooltip
          class="item"
          effect="dark"
          content="查看日志"
          placement="top-start"
        >
          <el-button type="warning" @click="dialogOpen">查看日志</el-button>
        </el-tooltip>
        </el-col>
        <el-col :span="10">
            <div class="selectTitle">
               <span style="margin-right: 10px;font-weight: bold;">选择审核列表</span>
               <el-select @change="changeValue" v-model="SelectValue" clearable placeholder="请选择">
            <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
            </el-option>
        </el-select>
        </div>
        </el-col>
        <el-col :span="2">
        <el-tooltip
          class="item"
          effect="dark"
          content="发起申购"
          placement="top-start"
        >
          <el-button type="primary" @click="startSubscribe">发起申购</el-button>
        </el-tooltip>
        </el-col>
      </el-row>
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        v-if="tableData"
      >
        <!-- <el-table-column
          align="center"
          prop="id"
          label="列表ID"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="u_id"
          label="发起人ID"
        ></el-table-column> -->
        <el-table-column
          align="center"
          prop="add_time"
          label="发起时间"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="reason"
          label="申请事由"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="type_name"
          label="申购类型"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="delivery_time"
          label="期望使用时间"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="pay_name"
          label="付款方式"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="remark"
          label="备注"
        ></el-table-column>
        <el-table-column
          align="center"
          label="产品图"
        >
        <template slot-scope="scope">
            <img :src="scope.row.img[0]" style="width:50px;height:50px;" />
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
               <el-tooltip class="item" effect="dark"  content="查看申购单详情" placement="top-start">
            <el-button icon="el-icon-search" size="mini" @click="lookPurchase(scope.row.id)" >详情</el-button>
          </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @current-change="pageChange"
        @size-change="sizeChange"
        :page-sizes="[7, 15, 20]"
        layout="total, sizes, prev, pager, next"
        :page-size="this.getInfo.page_num"
        background
        :pager-count="15"
        :total="exp.num"
      >
      </el-pagination>
            </div>
    </div>
      
    </el-card>
    <!-- 发起申购模态框 -->
    <!-- 增加等级 -->
<el-dialog
      :visible.sync="AddDialogFormVisible"
      :append-to-body="true"
      :before-close="AddhandleClose"
    >
      <div slot="title" class="dialog-footer">
        <div class="title" >发起申购</div>
      </div>
      <div class="diaBox">
                 <el-form  :model="addLevelForm" label-width="100px" :rules="rules" ref="ruleForm">
        <el-form-item label="申请事由" prop="reason">
         <el-input v-model="addLevelForm.reason" placeholder="请输入申请事由(必填)" style="width:200px;"></el-input>
        </el-form-item>
        <el-form-item label="采购类型" prop="typeid">
         <!-- <el-input v-model="addLevelForm.type_id" placeholder="请输入采购类型id(必填)" style="width:200px;"></el-input> -->
         <el-select v-model="addLevelForm.type_id" clearable placeholder="请选择">
    <el-option
      v-for="item in optionsType"
      :key="item.value"
      :label="item.name"
      :value="item.id">
    </el-option>
  </el-select>
        </el-form-item>
        <el-form-item label="期望交付日期" prop="deliverytime">
         <!-- <el-input v-model="addLevelForm.delivery_time" placeholder="请输入期望交付日期(必填)" style="width:200px;"></el-input> -->
          <el-date-picker
      v-model="addLevelForm.delivery_time"
      value-format="yyyy-MM-dd hh:mm:ss"
      type="datetime"
      placeholder="选择日期时间">
    </el-date-picker>
        </el-form-item>
                <el-form-item label="付款方式" prop="payid">
         <!-- <el-input v-model="addLevelForm.pay_id" placeholder="请输入付款方式(必填)" style="width:200px;"></el-input> -->
             <el-select v-model="addLevelForm.pay_id" clearable placeholder="请选择">
    <el-option
      v-for="item in optionsPayType"
      :key="item.value"
      :label="item.name"
      :value="item.id">
    </el-option>
  </el-select>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
         <el-input v-model="addLevelForm.remark" placeholder="请输入备注(必填)" style="width:200px;"></el-input>
        </el-form-item>
        <el-form-item label="图片" prop="img">
          <el-upload
              :headers="token"
              accept=".jpg,.png"
              :on-remove="imgRemove"
              :limit="9"
              :action="imgUrl"
              list-type="picture"
              :on-exceed="exceed"
              :on-success="imgSuccess"
              name="image"
              :file-list="fileList"
            >
            <el-button size="small" type="primary">上传图片</el-button>
            </el-upload>
        </el-form-item>
           <div class="titles">采购明细</div>
        <div v-for="(item,index) in addLevelForm.detaic" :key="index">
        
        <el-form-item label="采购商品名称" prop="quantity">
         <el-input v-model="addLevelForm.detaic[index].name" placeholder="请输入采购商品名称(必填)" style="width:200px;"></el-input>
        </el-form-item>
        <el-form-item label="规格" prop="specif">
         <el-input v-model="addLevelForm.detaic[index].specif" placeholder="请输入规格(必填)" style="width:200px;"></el-input>
        </el-form-item>
        <el-form-item label="数量" prop="quantity">
         <el-input v-model="addLevelForm.detaic[index].quantity" placeholder="请输入数量(必填)" style="width:200px;"></el-input>
        </el-form-item>
        <el-form-item label="单位" prop="nuit">
         <el-input v-model="addLevelForm.detaic[index].nuit" placeholder="请输入单位(必填)" style="width:200px;"></el-input>
        </el-form-item>
        <el-form-item label="价格" prop="pay">
         <el-input v-model="addLevelForm.detaic[index].pay" placeholder="请输入价格(必填)" style="width:200px;"></el-input>
        </el-form-item>
        <div class="btn">
            <el-button  type="primary" v-if="detaiclength==index" @click="addDetaic">添加采购明细</el-button>
            <el-button  type="danger" v-if="detaiclength==index&&index!==0" @click="delDetaic(index)">删除采购明细</el-button>
        </div>
        
         </div>
      </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addExpCancel">取 消</el-button>
        <el-button type="primary" @click="setAdd('ruleForm')"
          >确 认</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { 
  PostListPurchase,
  PostAddPurchase,
  PostSelectType,
  PostPaySelectPay
} from "@/api/mysubscribe/mySubscribe";

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {    
    //这里存放数据
     var validateReason = (rule, value, callback) => {
        if (this.addLevelForm.reason === '') {
          callback(new Error('申请事由不能为空！'));
        } else {
          callback();
        }
      };
      var validateTypeid = (rule, value, callback) => {
        if (this.addLevelForm.type_id === '') {
          callback(new Error('采购类型不能为空！'));
        } else {
          callback();
        }
      };
      var validateDeliverytime = (rule, value, callback) => {
        if (this.addLevelForm.delivery_time === '') {
          callback(new Error('期望交付日期不能为空！'));
        } else {
          callback();
        }
      };
      var validatePayid = (rule, value, callback) => {
        if (this.addLevelForm.pay_id === '') {
          callback(new Error('付款方式不能为空！'));
        } else {
          console.log(111)
          callback();
        }
      };
      var validateRemark = (rule, value, callback) => {
        if (this.addLevelForm.remark === '') {
          callback(new Error('备注不能为空！'));
        } else {
          callback();
        }
      };
      var validateImg = (rule, value, callback) => {
        if (this.addLevelForm.img === '') {
          callback(new Error('图片不能为空！'));
        } else {
          callback();
        }
      };
      var validateDetaic = (rule, value, callback) => {
        if (this.addLevelForm.detaic === '') {
          callback(new Error('采购明细不能为空！'));
        } else {
          callback();
        }
      };
      var validateName = (rule, value, callback) => {
        if (this.addLevelForm.name === '') {
          callback(new Error('采购商品名称不能为空！'));
        } else {
          callback();
        }
      };
      var validateSpecif = (rule, value, callback) => {
        if (this.addLevelForm.specif === '') {
          callback(new Error('规格不能为空！'));
        } else {
          callback();
        }
      };
      var validateQuantity = (rule, value, callback) => {
        if (this.addLevelForm.quantity === '') {
          callback(new Error('数量不能为空！'));
        } else {
          callback();
        }
      };
      var validateNuit = (rule, value, callback) => {
        if (this.addLevelForm.nuit === '') {
          callback(new Error('单位不能为空！'));
        } else {
          callback();
        }
      };
      var validatePay = (rule, value, callback) => {
        if (this.addLevelForm.pay === '') {
          callback(new Error('价格不能为空！'));
        } else {
          callback();
        }
      };
    return {
      optionsType:[],//采购类型数组
      optionsPayType:[],//付款方式数组
      fileList: [], //上传图片的列表
      imgParam: {
        image: "",
      },
      token: {
        "Accept-Token": window.sessionStorage.getItem("token"),
      },
        rules: {
           reason: [
            { validator: validateReason, trigger: 'blur' }
          ],
          typeid: [
            { validator: validateTypeid, trigger: 'blur' }
          ],
          deliverytime: [
            { validator: validateDeliverytime, trigger: 'blur' }
          ],
          payid: [
            { validator: validatePayid, trigger: 'blur' }
          ],
          remark: [
            { validator: validateRemark, trigger: 'blur' }
          ],
          img: [
            { validator: validateImg, trigger: 'blur' }
          ],
          name: [
            { validator: validateName, trigger: 'blur' }
          ],
          specif: [
            { validator: validateSpecif, trigger: 'blur' }
          ],
          quantity: [
            { validator: validateQuantity, trigger: 'blur' }
          ],
          nuit: [
            { validator: validateNuit, trigger: 'blur' }
          ],
          pay: [
            { validator: validatePay, trigger: 'blur' }
          ],
        },
      addLevelForm:{
        reason:'',//申请事由
        type_id	:'',//采购类型id
        delivery_time:'',//期望交付日期
        pay_id:'',//付款方式id
        remark:'',//采购明细
        img:[],//图片
        detaic:[{
          name:'',//采购商品名称
        specif:'',//规格
        quantity:'',//数量
        nuit:'',//单位
        pay:'',//价格
        }],//采购明细 
    },
    detaiclength:0,
       //增加等级模态框
    AddDialogFormVisible:false,
        options: [{
          value: '0',
          label: '查看拒绝审核列表'
        }, {
          value: '1',
          label: '查看申购审核列表'
        }, {
          value: '2',
          label: '查看部门审核列表'
        }, {
          value: '3',
          label: '查看财务审核列表'
        }, {
          value: '4',
          label: '查看老板审核列表'
        }, {
          value: '5',
          label: '查看财务执行列表'
        }],
        SelectValue: '1',
      //查看日志模态框
      dialogLog: {
        state: false,
      },
      //总条数
      exp: {
        count: Number,
        num: 0,
      },
      //当前页码
      getInfo: {
        //搜索框
        info: "",
        page_code: 1,
        page_num: 15,
      },
      //表格数据
      tableData: [],
    };
  },
  //监听属性 类似于data概念
   computed: {
    imgUrl() {
      return this.$store.state.imgUrl;
    },
  },
  //监控data中的数据变化
  watch: {
     AddDialogFormVisible(newVal, oldVal) {
      if (!newVal) {
        this.addLevelForm = this.$options.data().addLevelForm;
        this.fileList = this.$options.data().fileList;
      }
       this.detaiclength=this.addLevelForm.detaic.length-1;
    },
  },
  //方法集合
  methods: {
    //删除采购明细
    delDetaic(i){
          this.addLevelForm.detaic.pop();
          this.detaiclength=this.addLevelForm.detaic.length-1;  
    },
    //增加采购明细
    addDetaic(){
       this.addLevelForm.detaic.push(
         {
          name:'',//采购商品名称
        specif:'',//规格
        quantity:'',//数量
        nuit:'',//单位
        pay:'',//价格
        }
       )
       this.detaiclength=this.addLevelForm.detaic.length-1;
    },
    //发起申购
    setAdd(from){
         this.$refs[from].validate((valid) => {
          if (valid) {
            this.$confirm('确认发起申购？')
          .then(_ => {
            this.AddDialogFormVisible=false;
            console.log(this.addLevelForm);
             this.PostAddPurchase(this.addLevelForm);
          })
          .catch(_ => {});
           
          } else {
            console.log('error submit!!');
            return false;
          }
        });
        
    },
     exceed() {
      this.$message({
        message: "数量超出",
        type: "warning",
      });
    },
        //取消增加等级模态框
    addExpCancel(){
          this.AddDialogFormVisible=false;
    },
      //发起申购
      startSubscribe(){
         this.AddDialogFormVisible=true;
      },
       //导入图片事件
    imgSuccess(response, file, fileList) {
      var url=response.data.url;
      this.addLevelForm.img.push(url);
    },

    //移除图片的事件
    imgRemove(response, file, fileList) {
      console.log(response)
      var url=response.response.data.url;
      var index=this.addLevelForm.img.indexOf(url);
      this.addLevelForm.img.splice(index,1);
    },
      //下拉框变化事件
      changeValue(){
            console.log(this.SelectValue)
      },
      //查看申购单详情
      lookPurchase(id){
        this.$router.push({
          path:'/mysubscribe/detail',
          query:{id}
        })
       console.log("查看申购单详情",id)
      },
    //查看日志
    dialogOpen() {
      this.dialogLog.state = true;
    },
    //修改每页数量
    sizeChange(value) {
      this.getInfo.page_num = value;
      var data = {
        page: this.getInfo.page_code,
        list_num: this.getInfo.page_num,
      };
      console.log(data);
      this.tableData = [];
      // this.PostLevelList(data);
    },
    //分页切换
    pageChange(value) {
      this.getInfo.page_code = value;
      var data = {
        page: this.getInfo.page_code,
        list_num: this.getInfo.page_num,
      };
      console.log(data);
      this.tableData = [];
      // this.PostLevelList(data);
    },
        AddhandleClose(done){
          this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {});
      },
    /**-----------------网络请求生命周期函数--------------------- */
    //付款方式下拉 
   async PostPaySelectPay(){
      var res=await PostPaySelectPay();
      if(res.data.code==0){
        this.optionsPayType=res.data.data;
      }else{
        this.$message.error(res.data.message);
      }
    },
    //申购类型下拉网络请求
    async PostSelectType(){
       var res=await PostSelectType();
         if(res.data.code==0){
        this.optionsType=res.data.data;
      }else{
        this.$message.error(res.data.message);
      }
      //  console.log(res);
    },
    //发送申购网络请求
     PostAddPurchase(data){
      console.log(data)
      let resList=PostAddPurchase(data);
      resList.then((res)=>{
           if(res.data.code==0){
         this.tableData=[]
           var data = {
      page: this.getInfo.page_code,
      list_num: this.getInfo.page_num,
      status: this.SelectValue,
    };
    //查看自己申购审核列表
     this.PostListPurchase(data);
      }else{
          this.$message.error(res.data.message);
      }
      }).catch((err)=>{
        console.log(err)
      })
      
    },
    //查看自己申购审核列表网络请求
    async PostListPurchase(data) {
      let res = await PostListPurchase(data);
      if(res.data.code==0){
          this.tableData=this.tableData.concat(res.data.data);
      }else{
          this.$message.error(res.data.message);
      }
      console.log(res);
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    var data = {
      page: this.getInfo.page_code,
      list_num: this.getInfo.page_num,
      status: this.SelectValue,
    };
    //查看自己申购审核列表
     this.PostListPurchase(data);
  },
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    //付款方式下拉
    this.PostPaySelectPay();
    //申购类型下拉
    this.PostSelectType();
    //
    this.detaiclength=this.addLevelForm.detaic.length-1;
  },
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
};
</script>

<style scoped>
.page {
  margin-top: 20px;
}
.title {
  font-size: 18px;
  font-weight: bold;
}
.titles{
  margin-bottom: 20px;
  font-size: 16px;
  text-align: center;
  font-weight: bold;
}
.set-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.set-page-left {
  color: #0000006d;
  font-size: 14px;
  font-weight: 400;
}
.title {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}
.diaBox {
  display: flex;
  justify-content: center;
}
.selectTitle{
    display: flex;
    align-items: center;
    justify-content: center;
}
/* .btn{
  text-align: center;
} */
</style>